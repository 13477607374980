@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --swiper-theme-color: theme('colors.gray.800');
}

html,
body,
#__next {
  @apply w-full h-full;
}

#kustomer-ui-sdk-iframe {
  display: none !important;
}

/** TODO: Also show on FAQ page, Privacy Policy, and Terms of service page */

@layer base {
  .page-wrapper {
    @apply pt-5 pb-20 md:pt-16 md:pb-24;
    @apply min-h-[200px];
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

@layer components {
  /** DEPRECATED: Switch to tailwind macro and create components with a styled components like pattern */
  .badge {
    @apply inline-flex items-center px-3 py-1 text-sm font-medium rounded-md;
  }
  .badge--green {
    @apply text-green-800 bg-green-100;
  }
  .badge--red {
    @apply text-red-800 bg-red-100;
  }
  .checkbox-label-wrapper {
    @apply text-sm;
  }
  .checkbox-wrapper {
    @apply flex items-center h-5;
  }
  .checkbox {
    @apply w-4 h-4 text-gray-600 border-gray-300 rounded focus:ring-gray-500;
  }
  .radio-label-wrapper {
    @apply text-sm;
  }
  .radio-wrapper {
    @apply flex items-center h-5;
  }
  .radio {
    @apply w-4 h-4 text-gray-600 border-gray-300 rounded-full focus:ring-gray-500;
  }

  .quantity-selector {
    @apply space-x-2;
    @apply p-1;
    @apply overflow-hidden;
    @apply flex justify-start w-full;
    @apply border border-gray-200 border-solid rounded-md;
  }
  .quantity-selector__button {
    @apply w-full;
    @apply transition-all;
    @apply rounded-md active:border-transparent active:hover:bg-gray-300 active:bg-gray-100;
    @apply flex items-center justify-center px-5 py-3 text-sm font-medium uppercase sm:flex-1 hover:bg-gray-50;
  }
  .quantity-selector__button[disabled] {
    @apply cursor-not-allowed;
    @apply opacity-20;
  }
  .quantity-selector__input {
    @apply w-full;
    @apply border-0;
    @apply focus:ring-gray-50;
    @apply flex items-center justify-center w-16 px-3 py-3 text-sm font-medium text-center uppercase sm:flex-1;
  }
  .quantiy-selector__input[disabled] {
    @apply opacity-20;
  }
  .quantity-selector__input::-webkit-inner-spin-button,
  .quantity-selector__input::-webkit-outer-spin-button {
    @apply appearance-none;
    margin: 0;
  }

  .loader {
    @apply text-gray-500 animate-spin;
  }
  
  .button--loading {}
}
